import { http } from './http';
import _ from 'lodash';

export function addHook({
  address,
  label,
  hook_url,
  authorization,
  all_token,
  coin,
}) {
  return http
    .callApi({
      resource: "hook.add",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          address,
          label,
          hook_url,
          authorization,
          all_token,
          coin,
        }
      }
    }).then(e => e.data)
}



export function removeHook({
  address,
  coin,
}) {
  return http
    .callApi({
      resource: "hook.remove",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          address,
          coin,
        }
      }
    }).then(e => e.data)
}

export function listHook({
  coin,
  from_id = '',
  limit = 10,
  action = "next",
}): Promise<{ result, ended }> {
  return http
    .callApi({
      resource: "hook.list",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          coin,
          from_id,
          limit,
          action,
        }
      }
    }).then(e => e.data)
}

export function getHook({address, coin}) {
  let body = _.assign(
    {
      address: address,
      coin: coin,
    },
  );

  return http
    .callApi({
      resource: 'hook.detail',
      data: body,
    })
    .then(e => e.data);
}

export function listTransaction({
  from_id,
  address = '',
  limit = 10,
  coin,
  action = "next",
}): Promise<{ data, ended }> {
  return http
    .callApi({
      resource: "hook.transaction",
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        body: {
          from_id,
          address,
          limit,
          coin,
          action,
        }
      }
    }).then(e => e.data)
}