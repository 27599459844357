





































































































import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import CoolapseCard from "@/components/shared/CoolapseCard.vue";
import { loadings, mounted, errorToastHandler, errorPopupHandler } from "../../../utils/decorators/VueTimmer";
import { addHook, listHook, removeHook } from "@/apis/hook.api";

@Component({
  components: {
    CoolapseCard,
  }
})
export default class CurrenciesPage extends Vue {
  get coin(): string {
    return this.$route.params.coin;
  }

  loadings = {
    creating: false,
    loadhook: false,
    removing : {}
  };

  pageing = {
    canNext:false,
    canPrev:false,
  };


  @Provide()
  form = {
    address: "",
    label: "",
    hook_url: "",
    authorization: "",
    all_token: ""
  };

  @loadings("loadings.creating")
  @errorPopupHandler("Fail to create webhook")
  onCreateWebhook() {
    return addHook({
      ...this.form,
      coin: this.coin
    }).finally(() => {
      this.loadHook()
    })
  }

  hooks = []

  @mounted
  @loadings("loadings.loadhook")
  loadHook(){
    return listHook({
      coin: this.coin,
    }).then(({result,ended}) => {
      console.log(result)
      this.hooks = result
      this.pageing.canNext = !ended
    })
  }

  @loadings("loadings.loadhook")
  onPrev(){
    return listHook({
      coin: this.coin,
      action:"prev",
      from_id: this.hooks[0] && this.hooks[0]._id || ""
    }).then(({result,ended}) => {
      this.hooks = result
      this.pageing.canPrev = !ended
    })
  }

  @loadings("loadings.loadhook")
  onNext(){
    return listHook({
      coin: this.coin,
      action:"next",
      from_id: this.hooks[this.hooks.length - 1] && this.hooks[this.hooks.length - 1]._id || ""
    }).then(({result,ended}) => {
      this.hooks = result
      this.pageing.canNext = !ended
    })
  }

  @loadings(address => "loadings.removing." + address)
  @errorPopupHandler("Fail to remove webhook")
  removeHook(address){
    return removeHook({
      address,
      coin: this.coin
    }).finally(() => {
      this.loadHook()
    })
  }
}
